import { useFormAction, useNavigation } from "@remix-run/react";
import { clsx, type ClassValue } from "clsx";
import { useState } from "react";
import { extendTailwindMerge } from "tailwind-merge";
import { extendedTheme } from "./extended-theme.ts";

export const appDetails = {
  version: "1.0.0.0",
  title: "Future Disruptions",
  titleShort: "DRI",
  tagline: "Measure your readiness for disruption, today.",
  description: (
    <p className="font-extralight text-2xl mt-2">
      The annual Disruptive Readiness Index benchmarks how businesses are
      responding to the speed of change, opportunity, and left-field competitive
      threats. It provides a comprehensive insight into the organisational
      acceptance, preparedness, and execution of strategies to address the
      future tectonic shifts that face both public and private institutions.
    </p>
  ),
  privacyLink: "https://aptim-solutions.com/privacy",
  termsLink: "https://aptim-solutions.com/terms-of-service",
  loginLink: "https://future-disruptions.com/login",
  signupLink: "https://future-disruptions.com/signup",
  supportLink: "https://aptim-solutions.freshdesk.com",
  companyName: "APTIM-Solutions",
  companyTagline: "Advancing Performance Through Input Management",
  companyShortName: "APTIM",
  companyBlurb: (
    <p className="font-extralight text-2xl mt-2">
      At APTIM, we specialise in advancing performance through strategic input
      management, helping organisations streamline operations, maximise
      resources, and achieve their highest potential. Our innovative solutions
      are designed to optimise every stage of your processes, delivering
      measurable improvements and driving sustainable growth. With APTIM, you
      gain a partner dedicated to precision, efficiency, and excellence in
      performance.
    </p>
  ),
  companyFooter: (
    <div>
      <div className="my-2 border-border border-[1px]" />
      <p className="font-bold">APTIM-Solutions Limited</p>
      <p>
        Registered 11976801 in England and Wales at 71-75 Shelton Street, Covent
        Garden, London, WC2H 9JQ, United Kingdom
      </p>
    </div>
  ),
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function formatColors() {
  const colors: (string | Record<string, string[]>)[] = [];
  for (const [key, color] of Object.entries(extendedTheme.colors)) {
    if (typeof color === "string") {
      colors.push(key);
    } else {
      const colorGroup = Object.keys(color as Record<string, string>).map(
        (subKey) => (subKey === "DEFAULT" ? "" : subKey)
      );
      colors.push({ [key]: colorGroup });
    }
  }
  return colors;
}

const customTwMerge = extendTailwindMerge<string, string>({
  extend: {
    theme: {
      colors: formatColors(),
      borderRadius: Object.keys(extendedTheme.borderRadius),
    },
    classGroups: {
      "font-size": [
        {
          text: Object.keys(extendedTheme.fontSize),
        },
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}

export function getErrorMessage(error: unknown) {
  if (typeof error === "string") return error;
  if (
    error &&
    typeof error === "object" &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    return error.message;
  }
  console.error("Unable to get error message for error", error);
  return "Unknown Error";
}

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get("X-Forwarded-Host") ??
    request.headers.get("host") ??
    new URL(request.url).host;
  const protocol = host.includes("localhost") ? "http" : "https";
  return `${protocol}://${host}`;
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
  ...headers: Array<ResponseInit["headers"] | null | undefined>
) {
  const combined = new Headers();
  for (const header of headers) {
    if (!header) continue;
    for (const [key, value] of new Headers(header).entries()) {
      combined.append(key, value);
    }
  }
  return combined;
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = "POST",
  state = "non-idle",
}: {
  formAction?: string;
  formMethod?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
  state?: "submitting" | "loading" | "non-idle";
} = {}) {
  const contextualFormAction = useFormAction();
  const navigation = useNavigation();
  const isPendingState =
    state === "non-idle"
      ? navigation.state !== "idle"
      : navigation.state === state;
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  );
}

function callAll<Args extends Array<unknown>>(
  ...fns: Array<((...args: Args) => unknown) | undefined>
) {
  return (...args: Args) => fns.forEach((fn) => fn?.(...args));
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
  const [doubleCheck, setDoubleCheck] = useState(false);

  function getButtonProps(
    props?: React.ButtonHTMLAttributes<HTMLButtonElement>
  ) {
    const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>["onBlur"] =
      () => setDoubleCheck(false);

    const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>["onClick"] =
      doubleCheck
        ? undefined
        : (e) => {
            e.preventDefault();
            setDoubleCheck(true);
          };

    const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>["onKeyUp"] = (
      e
    ) => {
      if (e.key === "Escape") {
        setDoubleCheck(false);
      }
    };

    return {
      ...props,
      onBlur: callAll(onBlur, props?.onBlur),
      onClick: callAll(onClick, props?.onClick),
      onKeyUp: callAll(onKeyUp, props?.onKeyUp),
    };
  }

  return { doubleCheck, getButtonProps };
}

export const getUserIp = async () => {
  const response = await fetch("https://api.ipify.org/?format=json");
  if (!response.ok) {
    console.log("error not ok");
    return "";
  }
  const json = await response.json();
  return json.ip;
};

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
  ...responseInits: Array<ResponseInit | null | undefined>
) {
  let combined: ResponseInit = {};
  for (const responseInit of responseInits) {
    combined = {
      ...responseInit,
      headers: combineHeaders(combined.headers, responseInit?.headers),
    };
  }
  return combined;
}

export function getUserImgSrc(imageId?: string | null) {
  return imageId ? `/app/resources/user-images/${imageId}` : "/img/user.png";
}
